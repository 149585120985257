<template>
    <div class="forgot-password">
        <transition
                mode="out-in"
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut">
            <div>
                <base-modal
                        title="Forgot Password"
                        :close="false"
                >
                    <template #default>
                        <div>
                            <div style="width: 100%; clear: both; padding-top: 5px">
                                <div>
                                    <base-input for-i-d="email" label="Your email" element-type="input"
                                                v-model="model.email" input-type="text" :inputClass="'flat'">
                                    </base-input>
                                </div>
                            </div>
                            <div id="card-error" style="color: red">
                                <span v-for="error in errorMessages" :key="error.message">{{ error.message
                                    }}<br/></span>
                            </div>
                            <div style="color: green" v-if="successMessages.length > 0">
                                <span v-for="success in successMessages" :key="success.message">{{ success.message
                                    }}<br/></span>
                            </div>
                        </div>
                    <!-- </template>
                    <template #footer> -->
                        <div class="button-row">
                            <base-button
                                    style="margin-top: 10px;"
                                    mode="blue"
                                    :link="false"
                                    @click.native="resetPassword"
                            >Reset password
                            </base-button>
                            <base-button
                                    style="margin-top: 10px;"
                                    mode="white"
                                    :link="false"
                                    @click.native="routeTo('/Login')"
                            >Back
                            </base-button>
                        </div>
                        
                    </template>
                </base-modal>
            </div>
        </transition>
    </div>
</template>

<script>
import AppService from "./../../services/app.service";

export default {
    name: "ForgotPassword",
    data: function () {
        return {
            errorMessages: [],
            successMessages: [],
            model: {
                refno: window.bootUp.refno
            }
        };
    },
    methods: {
        validate: function () {
            this.errorMessages = [];
            this.successMessages = [];
            if (!(this.model?.email?.length > 0)) {
                this.errorMessages.push({message: 'Email is required'});
            }
            return this.errorMessages.length === 0;
        },
        resetPassword: function () {
            let isValid = this.validate();
            if (isValid) {
                AppService.resetPassword(this.model).then(() => {
                    this.successMessages.push({message: 'Please check your email address and follow the necessary steps to reset your password!'})
                }).catch(() => {
                    this.errorMessages.push({message: 'There was an issue with the email you provided. Please try again later!'});
                });
            }
        }
    }
}
</script>
<style>
footer, header {
    padding-left: 14px;
}
.forgot-password footer {
    display: none!important;;
}
</style>

<style lang="scss" scoped>
    .button-row {
        margin: 35px 0 20px 0;
    }
</style>
